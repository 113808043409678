import styled from 'styled-components'
import { textAlign } from 'styled-system'

import theme from 'styles/theme'

export const ListWrapper = styled.ul`
  list-style: none;
  width: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

  > li {
    width: 100%;
  }

  ${theme.mediaQueries.medium} {
    margin: -${theme.space[6]} auto;
    padding: ${theme.space[6]} 0 0 20px;

    > li {
      background-clip: padding-box;
      border: 0 solid transparent;
      border-width: 0 20px ${theme.space[6]} 0;
      width: 50%;

      &:only-child {
        flex: 0 0 100%;
        border-width: 0;
      }
    }
  }
`

export const Section = styled.section`
  ${textAlign}

  ${theme.mediaQueries.medium} {
    margin: 0 auto;
    max-width: 1020px;
  }
`

export default {
  ListWrapper,
  Section,
}
