/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { getPackages } from 'services/villageServices'
import { Container, Spacing50 } from 'styles/sharedStyle'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import CtaHeading from 'components/CtaHeading'
import { EditorialPromoList } from 'components/EditorialPromo'

// import Button from 'components/Button'

const ShoppingPackagesLandingPage = (props) => {
  const {
    pageContext: { villageServicesEndPoint, pageLevel },
    data: {
      page: {
        hero,
        headerText,
        headerTitle,
        packageDetailButtonLabel,
        packageItemsIntro,
        locale,
        village: {
          code,
          villageSlug,
          // servicesHeader,
          // home:{
          //   footer
          // }
        },
        village,
        jsonLdSchema,
      },
    },
  } = props
  const { data } = props
  const villageName = data?.page?.village?.name

  const [packagesData, setPackagesData] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [hasError, setHasError] = useState(false)

  const getBulletPoints = (description) => {
    const res = description.replace(/\r?\n|\r/g, '').split('#BOLDSTART#')
    if (res.length > 1) {
      res.shift()
      return res.map((s) => {
        const bullet = s.substring(0, s.indexOf('#BOLDEND#'))
        return bullet.slice(-1) === ':' ? bullet.slice(0, -1) : bullet
      })
    }
    return res
  }

  useEffect(() => {
    if (isFetching) {
      const currDate = new Date()
      const dateString = `${currDate.getFullYear()}-${currDate.getMonth() +
        1}-${currDate.getDate()}`
      getPackages(villageServicesEndPoint, locale, code, dateString).then(
        (resp) => {
          if (resp.data && resp.data.RequestStatus.Success) {
            const packagesInfo = []
            resp.data.Packages.forEach((_package) => {
              const packageData = {}
              packageData.ImageUrl =
                _package.Images.length > 0 &&
                _package.Images.find((x) => x.Lead)
                  ? _package.Images.find((x) => x.Lead).StandardURL
                  : ''
              packageData.headline = _package.Package.PackageName
              packageData.bodyCopy = packageItemsIntro
              packageData.bulletPoints = getBulletPoints(
                _package.Package.Description
              )
              packageData.PackageId = _package.Package.PackageID
              packageData.PackageOrder = _package.Package.PackageOrder
              packageData.ctaCopy = packageDetailButtonLabel
              packageData.ctaUrl = `${villageSlug}/${locale}/services/shopping-packages`
              packageData.isPackageEditorial = true
              packagesInfo.push(packageData)
            })
            packagesInfo.sort((a, b) => a.PackageOrder - b.PackageOrder)
            setPackagesData(packagesInfo)
          } else {
            setHasError(true)
          }
          setIsFetching(false)
        }
      )
    }
  }, [isFetching])

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      <CtaHeading
        headline={headerTitle}
        body={headerText ? headerText.headerText : ''}
        villageName={
          pageLevel === 'collection' ? 'The Bicester Collection' : villageName
        }
      />
      {hasError ? (
        <Container py={[60]} textAlign="center" maxWidth={[380]}>
          Oops something went wrong...
        </Container>
      ) : (
        packagesData && <EditorialPromoList items={packagesData} />
      )}
    </Layout>
  )
}

ShoppingPackagesLandingPage.propTypes = {
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
    villageServicesEndPoint: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

// eslint-disable-line no-unused-vars
export const ShoppingPackagesLandingPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateShoppingPackagesLandingT15(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      hero {
        eyebrow
        headline
        ...heroMediaQuery
      }
      locale: node_locale
      headerText {
        headerText
      }
      headerTitle
      packageDetailButtonLabel
      packageItemsIntro
      village {
        name
        code
        villageSlug: slug
        currency
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          footer {
            ...footer
          }
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default ShoppingPackagesLandingPage
