import React from 'react'
import PropTypes from 'prop-types'

import { Title32, Body16 } from 'components/Typography'
import Link from 'components/Link'
import Button from 'components/Button'
import trackGTM from 'utils/trackGTM'
import { useComponentId, useFieldId } from 'utils/optimizely'

import {
  EditorialPromoImg,
  EditorialPromoMeta,
  Wrapper,
  EditorialImgSrcContainer,
  EditorialItemList,
} from './EditorialPromoCard.style'

const EditorialPromoCard = ({
  headline,
  bodyCopy,
  image,
  ctaUrl,
  ctaCopy,
  isFullWidth,
  ImageUrl,
  isPackageEditorial,
  bulletPoints,
  elementId,
}) => {
  const elId = useComponentId(elementId || 'Comp_promo_copy_cta_PRO01')

  return (
    <Wrapper isFullWidth={isFullWidth} id={useFieldId(elId, 'image-container')}>
      {!isPackageEditorial && image && image.image && image.image.landscape && (
        <EditorialPromoImg
          image={image.image.landscape.gatsbyImageData}
          isFullWidth={isFullWidth}
          alt={image.image.altText}
        />
      )}
      {isPackageEditorial && (
        <EditorialImgSrcContainer isFullWidth={isFullWidth}>
          {ImageUrl && (
            <img src={ImageUrl} alt={headline} id={useFieldId(elId, 'image')} />
          )}
        </EditorialImgSrcContainer>
      )}
      <EditorialPromoMeta
        isFullWidth={isFullWidth}
        isPackageEditorial={isPackageEditorial}>
        {headline && (
          <Title32 as="h4" id={useFieldId(elId, 'headline')}>
            {headline}
          </Title32>
        )}

        {bodyCopy && (
          <Body16 as="p" id={useFieldId(elId, 'copy')}>
            {bodyCopy}
          </Body16>
        )}

        {isPackageEditorial && bulletPoints && (
          <div>
            <p />
            <EditorialItemList>
              {bulletPoints.map((item, index) => (
                <li
                  key={`${item}_${index.toString()}`}
                  id={useFieldId(elId, `list-item-${index + 1}`)}>
                  {item}
                </li>
              ))}
            </EditorialItemList>
          </div>
        )}

        {ctaCopy && ctaUrl && (
          <Button
            onClick={() => {
              trackGTM(
                'cta interaction',
                'cta interaction',
                'in page cta',
                ctaCopy
              )
            }}
            id={useFieldId(elId, `cta`)}
            as={Link}
            to={ctaUrl}
            external={
              ctaUrl.indexOf('http:') === 0 || ctaUrl.indexOf('https:') === 0
            }>
            {ctaCopy}
          </Button>
        )}
      </EditorialPromoMeta>
    </Wrapper>
  )
}

EditorialPromoCard.propTypes = {
  elementId: PropTypes.string,
  headline: PropTypes.string,
  bodyCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaCopy: PropTypes.string,
  isFullWidth: PropTypes.bool,
  image: PropTypes.shape({
    image: PropTypes.shape({
      altText: PropTypes.string,
      landscape: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
  ImageUrl: PropTypes.string,
  isPackageEditorial: PropTypes.bool,
  bulletPoints: PropTypes.arrayOf(PropTypes.string),
}

EditorialPromoCard.defaultProps = {
  elementId: null,
  headline: null,
  bodyCopy: null,
  ctaUrl: null,
  ctaCopy: null,
  isFullWidth: false,
  ImageUrl: null,
  image: null,
  isPackageEditorial: false,
  bulletPoints: [],
}

export default EditorialPromoCard
