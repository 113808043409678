import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import { Container, Spacing50 } from 'styles/sharedStyle'
import { useComponentId } from 'utils/optimizely'
import EditorialPromoCard from './EditorialPromoCard'
import { ListWrapper, Section } from './EditorialPromoList.style'
import { fixAudiences } from '../../utils/audiences'

const EditorialPromoList = ({ items, audiences }) => {
  const hasAudiences = !!audiences
  const elementId = useComponentId('Comp_promo_copy_cta_PRO01')

  return (
    <Container
      id={elementId}
      hasAudience={hasAudiences}
      className={fixAudiences(audiences)}
      p={`0 ${theme.space[4]}`}>
      <Spacing50 />
      <Section>
        {items && items.length && items.length > 1 && (
          <ListWrapper>
            {items.map((item, index) => (
              <li key={`${item.headline}_${index.toString()}`}>
                <EditorialPromoCard {...item} elementId={elementId} />
                <Spacing50 />
              </li>
            ))}
          </ListWrapper>
        )}

        {items && items.length && items.length === 1 && (
          <EditorialPromoCard {...items[0]} isFullWidth elementId={elementId} />
        )}
      </Section>
      <Spacing50 />
    </Container>
  )
}

EditorialPromoList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  audiences: PropTypes.string,
}

EditorialPromoList.defaultProps = {
  audiences: '',
}

export default EditorialPromoList
