import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  color: ${theme.colors.sagemedium};
  margin-bottom: ${theme.space[6]};

  ${(props) =>
    props.isFullWidth
      ? `
        display: grid; grid-template-rows: min-content 1fr; border: 0;
        ${theme.mediaQueries.medium} {
          border: 0;
          display: flex;
          flex-direction: row;
        }
      `
      : `display: grid; grid-template-rows: min-content 1fr; border: 0;`}

  h4 {
    margin: ${theme.space[4]} ${theme.space[2]} 0 ${theme.space[2]};
    padding: 0;
  }

  p {
    margin: ${theme.space[2]} ${theme.space[2]} 0 ${theme.space[2]};
    padding: 0;
  }

  a,
  button {
    margin: ${theme.space[6]} ${theme.space[2]} 0 ${theme.space[2]};
  }

  ${theme.mediaQueries.mobile} {
    h4 {
      padding: 0 ${theme.space[2]};
    }

    p {
      padding: 0 ${theme.space[2]};
    }

    a,
    button {
      margin: ${theme.space[6]} ${theme.space[2]} 0 ${theme.space[2]};
    }
  }
`

export const EditorialPromoMeta = styled.div`
  background: ${theme.colors.white};
  overflow: hidden;
  position: relative;
  word-break: break-word;

  ${(props) =>
    props.isFullWidth
      ? `
        text-align: center;

        ${theme.mediaQueries.medium} {
          padding: ${theme.space[6]};
          text-align: left;

          html[dir='rtl'] & {
            text-align: right;
          }
        }
      `
      : `text-align: center;`}

  ${(props) =>
    props.isPackageEditorial
      ? `color: ${theme.colors.sagedark};

    p,
    div {
      color: ${theme.colors.sagemedium};
    }
    `
      : ``}
`

export const EditorialPromoImg = styled(GatsbyImage)`
  display: block;
  height: auto;
  max-height: 540px;
  max-width: 100%;
  width: 100%;

  ${(props) =>
    props.isFullWidth &&
    `${theme.mediaQueries.medium} { min-width: 40%; }
  `}

  ${theme.mediaQueries.medium} {
    height: 30vw;

    ${(props) =>
      props.isFullWidth ? `max-height: 400px; width: 50%;` : `height: 100%;`}
  }
`
export const EditorialImgSrcContainer = styled.div`
  height: 45vh;
  text-align: center;

  ${theme.mediaQueries.medium} {
    ${(props) =>
      props.isFullWidth
        ? `max-height: 400px; height: auto; line-height: auto; min-width: 40%;  width: 50%;`
        : ``}
  }

  ${theme.mediaQueries.mobile} {
    height: auto;
  }

  img {
    height: inherit;

    ${theme.mediaQueries.mobile} {
      width: 100%;
    }
  }
`
export const EditorialItemList = styled.ul`
  line-height: 1.5em;
  list-style-type: none;
  padding-left: 0;

  li:before {
    content: '– ';
  }
`
